import React, { useRef, useState } from "react";

// components
import MailchimpSubscribe from "react-mailchimp-subscribe";

const SubscribeBar = () => {
	const mailChimpUrl =
		"https://graindiscovery.us7.list-manage.com/subscribe/post?u=d7bbcb66546cdec062f65fdfc&amp;id=a5563382a2";

	return (
		<div className="w-max mx-auto">
			<div className="">
				<MailchimpSubscribe
					url={mailChimpUrl}
					render={({ subscribe, status, message }) => (
						<CustomForm
							status={status}
							message={message}
							onValidated={(formData) => subscribe(formData)}
						/>
					)}
				/>
			</div>
		</div>
	);
};

const CustomForm = ({ status, message, onValidated }) => {
	let emailRef = useRef();
	const [invalidEmail, setInvalidEmail] = useState(false);

	const submit = (e) => {
		e.preventDefault();

		if (emailRef.current.value.indexOf("@") === -1) {
			setInvalidEmail(true);
			return;
		} else {
			setInvalidEmail(false);
		}

		emailRef.current.value &&
			emailRef.current.value.indexOf("@") > -1 &&
			onValidated({
				EMAIL: emailRef.current.value,
			});
	};
	console.log(status, message)

	return (
		<>
			<div className="bg-white px-4 py-2 rounded-xl items-center justify-center mb-8">
				<input
					className="font-brewski text-2xl"
					ref={emailRef}
					type="EMAIL"
					name="EMAIL"
					placeholder="Your Email"
				/>
				<button
					className="p-3 font-brewski text-2xl bg-footer rounded-xl"
					onClick={submit}
				>
					Submit
				</button>
				{invalidEmail && (
					<div className="text-red-500">
						Please enter a valid email addresss.
					</div>
				)}

				{status === "error" && (
					<div
						className="text-red-500"
						dangerouslySetInnerHTML={{ __html: message }}
					/>
				)}
				{status === "success" && (
					<div
						className="text-green-500"
						dangerouslySetInnerHTML={{ __html: message }}
					/>
				)}
			</div>
		</>
	);
};
export default SubscribeBar;
