import "./App.css";
import LotTrace from "./views/lotTrace/LotTrace";
import About from "./views/about/About";
import Map from "./views/map/Map";
import MoreInfoBarley from "./views/moreInfo/MoreInfoBarley";
import MoreInfoMalt from "./views/moreInfo/MoreInfoMalt";
import MoreInfoHops from "./views/moreInfo/moreInfoHops";

import {
	BrowserRouter,
	Switch,
	Route,
} from "react-router-dom";

import MoreInfoBrew from "./views/moreInfo/MoreInfoBrew";
import ReactGA from "react-ga";

import ValueProp from "./views/v2/value_prop/ValueProp";
import Timeline from "./views/v2/timeline/Timeline";
import Partners from "./views/v2/partners/Parters";
import Footer from "./views/v2/footer/Footer";
import Barley3 from "./assets/barley_illustration/barley_3.png";
import Barley4 from "./assets/barley_illustration/barley_4.png";
import SaskBarley from "./views/v2/SaskBarley/SaskBarley";
import SaskMalts from "./views/v2/SaskMalts/SaskMalts";
import SaskBrewing from "./views/v2/SaskBrewing/SaskBrewing";
import SaskBreweries from "./views/v2/SaskBreweries/SaskBreweries";
function App(props) {
	ReactGA.initialize("UA-181372061-5", {
		debug: true,
	});

	return (
		<>
			<BrowserRouter basename={"/"}>
				<Switch>
					<Route exact test={"test"} path={"/"}>
						<>
							<div className="flex justify-center text-[25px] bg-background pt-10 h-[65px] relative">
								<img
									className="hidden md:block top-0 right-0 absolute z-10"
									src={Barley3}
									alt="Background Barley Illustration"
								></img>
								<img
									className="hidden md:block top-0 left-10 absolute z-10"
									src={Barley4}
									alt="Background Barley Illustration"
								></img>
								<a className="font-brewski text-black border-r border-black pr-3 cursor-pointer hover:text-[27px]" href="#journey">The Journey</a>
								<a className="font-brewski text-black border-r border-black pl-3 pr-3 cursor-pointer hover:text-[27px]" href="#partners">Partners</a>
								<a className="font-brewski text-black pl-3 cursor-pointer hover:text-[27px]" href="#contact">Contact Us</a>
							</div>
							<ValueProp></ValueProp>
							<Timeline></Timeline>
							{/* <Projects></Projects> */}
							<Partners></Partners>
							<Footer></Footer>
						</>
					</Route>
					<Route exact path={"/saskatchewan-barley"}>
						<SaskBarley />
					</Route>
					<Route exact path={"/saskatchewan-malts"}>
						<SaskMalts />
					</Route>
					<Route exact path={"/saskatchewan-brewing"}>
						<SaskBrewing />
					</Route>
					<Route exact path={"/saskatchewan-breweries"}>
						<SaskBreweries />
					</Route>
					<Route exact path={"/trace/:id"}>
						<LotTrace ga={ReactGA} />
					</Route>
					<Route exact path={"/about/:id"}>
						<About ga={ReactGA} />
					</Route>
					<Route exact path={"/map/:id"}>
						<Map ga={ReactGA} />
					</Route>
					<Route exact path={"/moreinfo/barley/:id"}>
						<MoreInfoBarley ga={ReactGA} />
					</Route>
					<Route exact path={"/moreinfo/malt/:id"}>
						<MoreInfoMalt ga={ReactGA} />
					</Route>
					<Route exact path={"/moreinfo/hops/:id"}>
						<MoreInfoHops ga={ReactGA} />
					</Route>
					<Route exact path={"/moreinfo/brew/:id"}>
						<MoreInfoBrew ga={ReactGA} />
					</Route>
				</Switch>
			</BrowserRouter>
		</>
	);
}

export default App;
