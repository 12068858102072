import React from "react";
import { useHistory } from "react-router-dom";

import arrowActive from "../assets/arrow_active.svg";
import orangeArrow from "../assets/orange-arrow.svg"
import brownArrow from "../assets/brown-arrow.svg"
import maroonArrow from "../assets/maroon-arrow.svg"
import arrowInactive from "../assets/arrow_inactive.svg";

export default function TimelineBar({ active = "barley" }) {
    return (
        <div className="bg-background flex mt-[150px] justify-center">
            <div className="relative">
                <p className={`font-brewski ${active?.toLowerCase() === "barley" ? 'text-[#FFAC5E]' : 'text-[#d9d9d9]'} text-center absolute text-[40px] top-[-60px] left-[80px]`}>Barley</p>
                <Arrow active={active?.toLowerCase() === "barley"} label="barley" />
            </div>
            <div>
                <Arrow active={active?.toLowerCase() === "malt"} label="malts" />
                <p className={`font-brewski ${active?.toLowerCase() === "malt" ? 'text-[#FF942E]' : 'text-[#d9d9d9]'} mt-[10px] text-center text-[40px]`}>Malts</p>
            </div>
            <div className="relative">
                <p className={`font-brewski ${active?.toLowerCase() === "brewing" ? 'text-[#C76407]' : 'text-[#d9d9d9]'} text-center absolute text-[40px] top-[-60px] left-[80px]`}>Brewing</p>
                <Arrow active={active?.toLowerCase() === "brewing"} label="brewing" />
            </div>
            <div>
                <Arrow active={active?.toLowerCase() === "breweries"} label="breweries" />
                <p className={`font-brewski ${active?.toLowerCase() === "breweries" ? 'text-[#9E5151]' : 'text-[#d9d9d9]'} text-center text-[40px] mt-[10px]`}>Breweries</p>
            </div>
        </div>
    );
}

const Arrow = ({ direction, label, active }) => {
    const history = useHistory()
    return (
        <div
            className={`slide-arrow ${direction}`}
        >
            {active ? (
                <img
                    src={label === 'barley' ? arrowActive : label === 'malts' ? orangeArrow : label === 'brewing' ? brownArrow : maroonArrow}
                    alt="Arrow"
                    className="hover:shadow-lg cursor-pointer"
                    onClick={() => history.push(`/saskatchewan-${label}`)}
                />
            ) : (
                <img
                    src={arrowInactive}
                    alt="Arrow"
                    className="hover:shadow-lg cursor-pointer"
                    onClick={() => history.push(`/saskatchewan-${label}`)}
                />
            )
            }
        </div>
    )
}
