import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import Barley3 from '../../../assets/barley_illustration/barley_3.png'
import Barley4 from '../../../assets/barley_illustration/barley_4.png'
import TimelineBar from '../../../components/TimelineBar'
import Footer from '../footer/Footer'
import MapGL from './MapGL'

export default function SaskBreweries() {
    const history = useHistory()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (
        <div className='bg-background h-full'>
            <div className="flex justify-center text-[25px] pt-10 h-[60px] relative">
                <img
                    className="hidden md:block top-0 right-0 absolute z-10"
                    src={Barley3}
                    alt="Background Barley Illustration"
                ></img>
                <img
                    className="hidden md:block top-0 left-10 absolute z-10"
                    src={Barley4}
                    alt="Background Barley Illustration"
                ></img>
                <span className="font-brewski text-black border-r border-black pr-3 cursor-pointer hover:text-[27px]" onClick={() => history.push("/")}>Home</span>
                <a className="font-brewski text-black pl-3 cursor-pointer hover:text-[27px]" href="#contact">Contact Us</a>
            </div>
            <h1 className="text-[#6B3B38] text-center text-[96px] font-brewski">
                Saskatchewan Breweries
            </h1>
            <TimelineBar active={"breweries"} />
            <h1 className="font-brewski text-[52px] tablet:text-[52px] text-center pb-5">
                Breweries in Saskatchewan
            </h1>
            <div className="mb-20 flex justify-center">
                <div className="border-b-8 rounded border-[#FFAC5E] w-[250px]" />
            </div>
            <MapGL />
            <Footer />
        </div>
    )
}