import saskpartners from "../../../assets/sask-partners.svg";

function Partners() {
  return (
    <div id="partners" className="px-16 pt-40 bg-background bg-background">
      <img
        className="mx-auto pb-20 object-fill"
        src={saskpartners}
        alt="Canadian Agricultural Partnership logo, Government of Saskatchewan logo, Government of Canada logo"
      ></img>

    </div>
  );
}

export default Partners;
