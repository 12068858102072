import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import Barley3 from '../../../assets/barley_illustration/barley_3.png'
import Barley4 from '../../../assets/barley_illustration/barley_4.png'
import growing1 from '../../../assets/growing-pt1.svg'
import growing2 from '../../../assets/growing-pt2.svg'
import TimelineBar from '../../../components/TimelineBar'
import Footer from '../footer/Footer'

export default function SaskBarley() {
    const history = useHistory()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='bg-background h-full'>
            <div className="flex justify-center text-[25px] pt-10 h-[60px] relative">
                <img
                    className="hidden md:block top-0 right-0 absolute z-10"
                    src={Barley3}
                    alt="Background Barley Illustration"
                ></img>
                <img
                    className="hidden md:block top-0 left-10 absolute z-10"
                    src={Barley4}
                    alt="Background Barley Illustration"
                ></img>
                <span className="font-brewski text-black border-r border-black pr-3 cursor-pointer hover:text-[27px]" onClick={() => history.push("/")}>Home</span>
                <a className="font-brewski text-black pl-3 cursor-pointer hover:text-[27px]" href="#contact">Contact Us</a>
            </div>
            <h1 className="text-[#6B3B38] text-center text-[96px] font-brewski">
                Saskatchewan Barley
            </h1>
            <TimelineBar active={"barley"} />
            <div className='mt-20 flex flex-col items-center'>
                <h1 className="font-brewski text-[52px] tablet:text-[52px] text-center pb-5">
                    History
                </h1>
                <div className="mb-20 flex justify-center">
                    <div className="border-b-8 rounded border-[#FFAC5E] w-[250px]" />
                </div>
                <div className='w-4/5 font-body text-[26px]'>
                    <p className='font-body mb-3'>Barley is the second most widely grown cereal crop in Canada after wheat. It contributes significantly to the world’s livestock feed, malt products, and human food supply.</p>
                    <p className='font-body mb-3'>European colonists brought Barley and other small grain cereals to Canada in the early 17th century, with the first Canadian brewery built in Quebec City in 1668. As the Prairies were settled, most barley was used as livestock feed. This all changed in 1918 when a National Barley Committee was established to promote crop development: this led to concerted efforts in barley improvement through research and development. These efforts were initiated at agricultural colleges and experimental farms in the prairie provinces, with the Crop Development Centre at the University of Saskatchewan being one of the most important.</p>
                    <p className='font-body mb-3'>Over the past two decades, SK barley production has averaged 3.5 million tonnes despite increased cropping options such as pulses and oilseeds. Generally, farmers have two marketing options: malt and feed. Cultivars are developed specifically for these purposes. Approximately 75% of acres sown to barley in Saskatchewan are malting type in the hope of capturing the premium malting market price. However, less than 20% of production is selected for malting, with the remainder sold as feed. Canada is a significant exporter of malt and malting barley, with China taking nearly 90% of all exports.</p>
                </div>
            </div>
            <div className='mt-20 flex flex-col items-center mb-20'>
                <h1 className="font-brewski text-[52px] tablet:text-[52px] text-center pb-5">
                    Growing Calendar
                </h1>
                <div className="mb-20 flex justify-center">
                    <div className="border-b-8 rounded border-[#FFAC5E] w-[250px]" />
                </div>
                <div className='w-4/5 font-body text-[26px]'>
                    <p className='font-body mb-3'>Barley is a widely grown adaptable plant, an important feed grain in many areas of the world constrained by a shorter growing season. Due to its early maturing characteristics and drought tolerance, it is a crucial rotation in Saskatchewan.</p>
                    <p className='font-body mb-3'>An annual plant is planted in the spring; once the ground has thawed and soil temperatures are warm enough for seeds to germinate, harvest is mid-summer. If Fall conditions are too wet to plant wheat or canola, farmers can switch to spring barley. One advantage of barley is that it is one of the cheapest crops to grow. Either too much or too little rain, and the timing is critical.</p>
                    <p className='font-body mb-3'>The malting industry is an important sector that requires a bright, clean seed with high germination characteristics and low protein. Excessive moisture on mature crops causes the seed to germinate prematurely, ruining malting capabilities. Extreme weather events like frosts or floods and excessive temperature during grain filling also can reduce high-quality supply, driving prices higher.</p>
                </div>
            </div>
            <img src={growing1} alt="Growing Calendar" className="w-full mt-[-200px]" loading="lazy" />
            <img src={growing2} alt="Growing Calendar" className="w-full lg:mt-[-530px] md:mt-[-370px] sm:mt-[-300px] " loading="lazy" />
            <h3 className="text-[#6B3B38] text-center text-[56px] font-brewski">
                Saskatchewan Malts
            </h3>
            <p className='font-body text-center w-1/2 text-[26px] mx-auto'>"The change in liquor laws in the late 80’s was one of the best things to happen in Saskatchewan. So many talented brewers and maltsters were able to start selling craft beer in the province.” - Bert Barkwell, Beer Historian</p>
            <div className='flex flex-col items-center mb-20'>
                <button
                    onClick={() => history.push("/saskatchewan-malts")}
                    className="bg-[#00374B] text-white text-[38px] font-brewski w-[300px] h-[70px] rounded-lg mx-auto mt-10 mb-20">
                    Discover More
                </button>
            </div>
            <Footer />
        </div>
    )
}