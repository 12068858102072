import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import BarleyIcon from "../../../assets/timeline_icon/barley.png";
import MaltIcon from "../../../assets/timeline_icon/malt.png";

import Malt1 from "../../../assets/malt_illustration/malt_1.png";
import Malt2 from "../../../assets/malt_illustration/malt_2.png";
import Malt3 from "../../../assets/malt_illustration/malt_3.png";

import BarleyImage from "../../../assets/timeline_picture/barley_stage.png";
import MaltImage from "../../../assets/timeline_picture/malt_stage.png";
import HopImage from "../../../assets/timeline_picture/hops_stage.png";
import BeerImage from "../../../assets/timeline_picture/beer_stage.png";
import curvyTimeline from "../../../assets/curvy-timeline.png"
import BrewTanks from "../../../assets/brew-tanks.svg"
import BeerMug from "../../../assets/beer-mug.svg"
import arrowRight from "../../../assets/arrow-right.svg"

const tabs = [
	{
		title: "The Barley Farmer",
		body: "Maybe the farmer is your neighbour? Get to know them better and hear their stories and passion on why Canada grows the worlds best barley! Farmers have an opportunity to participate in consumer-driven, value-add programs that drives a price premium.",
		detail: {
			farmer: "Gellert Farms",
			location: "Assiniboia, SK",
			planted: "May, 2021",
			havested: "Sep, 2021",
			variety: "Copeland",
		},
		image: BarleyImage,
		link: "/saskatchewan-barley",
		linkText: "Find out more"
	},
	{
		title: "The Malting",
		body: "Did you know malting is a three-part process that has remained unchanged for centuries! Brewery Discovery gives you a glimpse into this age-old practice.",
		detail: {
			supplier: "Makers Malt",
			location: "Rosthern, SK",
			malt_style: "Prairie Chocolate",
			batch: "156-25",
		},
		image: MaltImage,
		link: "/saskatchewan-malts",
		linkText: "Find out more"
	},
	{
		title: "The Brewing",
		body: "How fresh is your favourite bevvie? How much hops/barley are used? Who is the brew master and how did they make it taste like this! All these questions and more can now be finally answered!",
		detail: {
			brewery: "Saskatchewan River Pirate",
			location: "Patchewollock, SK",
			batch_code: "0786",
			beer_style: "Double IPA",
			malt_used: "1048 kg",
			hops_used: "51 kg",
			brewing_date: "19th Apr, 2022",
			canning_date: "8th May, 2022",
		},
		image: BeerImage,
		link: "/saskatchewan-brewing",
		linkText: "Find out more"
	},
	{
		title: "Saskatchewan Beer",
		body: "The last few years have seen an explosion in the number of craft breweries across the province, each with its own distinctive set of flavours!",
		detail: {},
		image: HopImage,
		link: "/saskatchewan-breweries",
		linkText: "Find out more"
	},
];

function Timeline() {
	const [currentTab, setCurrentTab] = useState(0);

	return (
		<div id="journey" className="px-16 py-16 bg-background bg-background relative">
			<h1 className="font-brewski text-[52px] tablet:text-[52px] text-center pb-5">
				The Journey
			</h1>
			<div className="mb-20 flex justify-center">
				<div className="border-b-8 rounded border-[#FFAC5E] w-[250px]" />
			</div>
			<img
				className="hidden md:block absolute top-36 left-0"
				src={Malt1}
				alt="Malt Illustration"
			></img>
			<div className="w-full h-full">
				<div className="w-full relative">
					<img src={curvyTimeline} alt="Curvy Timeline" className="w-1/3 mx-auto" />
					<div className="absolute top-[30px] sm:right-[-40px] sm:top-[0px] md:right-[0px] md:top-0 lg:right-[120px] xl:right-[420px]">
						<p className="text-[#4A1010] mb-2 sm:text-[28px] font-brewski text-[36px] tablet:text-[24px]">The Barley Farmer</p>
						<p className="font-body w-[300px] sm:w-[250px]">Some of the best barley in the world is grown right here, in Saskatchewan. The ideal mix of an arable climate, soil nutrients and barley focused institutions ensures that Saskatchewan Barley dominates globally!</p>
						<a className="text-xl pt-4 font-brewski text-[#00374B]" href="/saskatchewan-barley">
							Find out more <img src={arrowRight} alt="arrow right" className="inline-block h-6"></img>
						</a>
					</div>
					<div className="absolute top-[300px] sm:left-[-100px] sm:top-[160px] w-[325px] md:left-[-25px] md:top-[200px] lg:left-[100px] lg:top-[300px] xl:left-[400px] xl:top-[600px] flex flex-col items-end">
						<p className="text-[#4A1010] mb-2 sm:text-[28px] font-brewski text-[36px] tablet:text-[24px]">The Malting</p>
						<p className="font-body w-[300px] sm:w-[250px] text-right">Malt Houses are the translators between what brewers are looking for in terms of varieties and flavour profiles and what the certified seed growers are planting.</p>
						<a className="text-xl max-w-2xl pt-4 font-brewski text-[#00374B]" href="/saskatchewan-malts">
							Find out more <img src={arrowRight} alt="arrow right" className="inline-block h-6"></img>
						</a>
					</div>
					<div className="absolute top-[600px] sm:right-[-40px] sm:top-[300px] md:right-[0px] md:top-[400px] lg:right-[120px] xl:right-[420px] lg:top-[600px] xl:top-[1100px]">
						<p className="text-[#4A1010] mb-2 sm:text-[28px] font-brewski text-[36px] tablet:text-[24px]">The Brewing</p>
						<p className="font-body w-[300px] sm:w-[250px]">Brewing is the part we all know best, in Saskatchewan there is nothing quite like locally brewed beer. Local ingredients and local artisans create amazing local beer!</p>
						<a className="text-xl max-w-2xl pt-4 font-brewski text-[#00374B]" href="/saskatchewan-brewing">
							Find out more <img src={arrowRight} alt="arrow right" className="inline-block h-6"></img>
						</a>
					</div>
					<div className="absolute top-[880px] sm:left-[-100px] sm:top-[475px] w-[325px] md:left-[-25px] md:top-[600px] lg:left-[90px] xl:left-[400px] lg:top-[880px] xl:top-[1600px] flex flex-col items-end">
						<p className="text-[#4A1010] mb-2 sm:text-[28px] font-brewski text-right text-[36px] tablet:text-[24px]">Saskatchewan Beer</p>
						<p className="font-body w-[300px] text-right sm:w-[250px]">Craft breweries have taken the prairies by storm in recent years. Saskatchewan’s landscape is now covered in excellent craft breweries!</p>
						<a className="text-xl max-w-2xl pt-4 font-brewski text-[#00374B]" href="/saskatchewan-breweries">
							Find out more <img src={arrowRight} alt="arrow right" className="inline-block h-6"></img>
						</a>
					</div>
				</div>
			</div>

		</div>
	);
}

export default Timeline;
