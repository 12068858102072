export const BREWERIES = [
    {
        name: "Pile O' Bones Brewing",
        address: "1808 Cameron St, Regina, SK S4T 7R7",
        link: "https://www.pileobonesbrewing.com/taproom",
        lat: 50.449689239252606,
        lng: -104.62555769974855,
    },
    {
        name: "Crossmount Cider Company",
        address: "30 Glen Road, Crossmount, SK S7T 0X8",
        link: "http://crossmountcidercompany.ca/",
        lat: 52.03230788913994,
        lng: -106.67394338156315,
    },
    {
        name: "Rafter R Brewing Co.",
        address: "13 Pacific Avenue, Maple Creek, SK S0N 1N0",
        link: "http://www.rafterrbrewing.ca/",
        lat: 49.910455521797104,
        lng: -109.48313995580229,
    },
    {
        name: "Nokomis Brewery",
        address: "301 1st Ave E, Nokomis, SK S0G 3R0",
        link: "https://nokomiscraftales.com/",
        lat: 51.50576543128293,
        lng: -105.00526901534357,
    },
    {
        name: "Armoury Brewing",
        address: "702 102 St, North Battleford, SK S9A 1E3",
        link: "https://www.facebook.com/Armoury-Brewing-Company-161062101216551/",
        lat: 52.76753156718089,
        lng: -108.295797,
    },
    {
        name: "Prince Albert Brewery",
        address: "1425 6 Ave E, Prince Albert, SK S6V 1H7",
        link: "https://www.facebook.com/thenewpabcopage",
        lat: 53.200317643992406,
        lng: -105.73823491164103,
    },
    {
        name: "Black Bridge Brewery",
        address: "295 Alexander Dr, Swift Current, SK S9H 3W3",
        link: "https://www.facebook.com/blackbridgebrew",
        lat: 50.986673061199305,
        lng: -107.50060955264628,
    },
    {
        name: "Back Forty Brewery",
        address: "54 Darlington St W, Yorkton, SK S3N 3S3",
        link: "http://www.backfortyname.ca/",
        lat: 51.21636078939604,
        lng: -102.46624845397325,
    },
    {
        name: "Barley Mill Brew Pub",
        address: "6155 Rochdale Blvd, Regina, SK S4X 2R1",
        link: "http://barleymill.ca/",
        lat: 51.05798585161161,
        lng: -104.78767080258064,
    },
    {
        name: "District Brewing Company",
        address: "1555 8th Ave, Regina, SK S4R 1E6",
        link: "http://www.districtbrewing.ca/",
        lat: 50.4562608909246,
        lng: -104.60306692883556,
    },
    {
        name: "Malty National Brewing Co.",
        address: "1130 15th Ave, Regina, SK S4P 0Y5",
        link: "https://maltynational.com/",
        lat: 50.44269880428289,
        lng: -104.59714709815108,
    },
    {
        name: "Rebellion Brewing Co.",
        address: "1901 Dewdney Ave, Regina, SK S4R 8R2",
        link: "http://rebellionbrewing.ca/",
        lat: 50.45497920238138,
        lng: -104.60792358465774,
    },
    {
        name: "Tap Brew Pub & Eatery",
        address: "4245 Rochdale Blvd, Regina, SK S4X 4P7",
        link: "http://www.thetap.ca/",
        lat: 50.496137886639346,
        lng: -104.641680077262,
    },
    {
        name: "The Bushwakker",
        address: "2206 Dewdney Ave, Regina, SK S4R 1H3",
        link: "http://www.bushwakker.com/",
        lat: 50.45546367853627,
        lng: -104.6125488134933,
    },
    {
        name: "Warehouse Brewing Company",
        address: "102-1377 Hamilton St, Regina, SK S4R 2B6",
        link: "https://www.warehousebrewingcompany.com/foodmenu",
        lat: 50.45755447832233,
        lng: -104.60908102698662,
    },
    {
        name: "21st Street Brewery",
        address: "243 21st St E, Saskatoon, SK S7K 0B7",
        link: "http://www.21ststreetname.ca/",
        lat: 52.12683279952418,
        lng: -106.66318458465774,
    },
    {
        name: "9 Mile Legacy Brewing Company",
        address: "229 20th St W, Saskatoon, SK S7M 0W8",
        link: "http://www.9milelegacy.com/",
        lat: 52.12608350740211,
        lng: -106.67277662698662,
    },
    {
        name: "Churchill Brewing Company",
        address: "150 105 Street East, Saskatoon, SK S7N 1Z3",
        link: "http://www.churchillbrewing.com/",
        lat: 52.13287163569397,
        lng: -106.59089778095986,
    },
    {
        name: "Clark's Crossing Brewpub",
        address: "3030 Diefenbaker Dr, Saskatoon, SK S7L 7K2",
        link: "https://www.clarkscrossingpub.com/",
        lat: 52.131320183812306,
        lng: -106.72739754232887,
    },
    {
        name: "Fox & Hounds Pub and Brewery",
        address: "20-7 Assiniboine Dr, Saskatoon, SK S7K 1H1",
        link: "http://www.foxandhoundspub.ca/",
        lat: 52.159901133935705,
        lng: -106.6455041828088,
    },
    {
        name: "Great Western Brewinng Co.",
        address: "519 2nd Ave N, Saskatoon, SK S7K 2C6",
        link: "http://gwbcshop.ca/",
        lat: 52.136249305001066,
        lng: -106.66117933438238,
    },
    {
        name: "High Key Brewing Co.",
        address: "102 23 St E, Saskatoon, SK S7K 0J2",
        link: "http://www.hkbrew.ca/",
        lat: 52.13106337463868,
        lng: -106.66404428196107,
    },
    {
        name: "Lucky Bastard Distillers",
        address: "814 47th St E, Saskatoon, SK S7K 0X4",
        link: "http://luckybastard.ca/",
        lat: 52.167209370981595,
        lng: -106.65193982698663,
    },
    {
        name: "Paddock Wood Brewing Co.",
        address: "116 103 St E B1, Saskatoon, SK S7N 1Y7",
        link: "http://www.paddockwoodbrewing.com/",
        lat: 52.131097286515306,
        lng: -106.59728322513769,
    },
    {
        name: "Prairie Sun Brewery",
        address: "650 Broadway Ave, Saskatoon, SK S7N 1A9",
        link: "http://www.prairiesun.ca/",
        lat: 52.119069746913446,
        lng: -106.65701565397325,
    },
    {
        name: "Saskatoon Brewery",
        address: "610 2nd Ave N, Saskatoon, SK S7K 2C8",
        link: "https://www.facebook.com/yxefactorytap/",
        lat: 52.137155853846735,
        lng: -106.65895079630214,
    }
]
