import React from "react";
import BeerQr from "../../../assets/beer_qr.png";
import Barley1 from "../../../assets/barley_illustration/barley_1.png";
import Barley2 from "../../../assets/barley_illustration/barley_2.png";
import saskcan from "../../../assets/sask-can.svg"

function ValueProp() {

	return (
		<div className="relative px-16 pt-20 pb-20 bg-background">
			<img
				className="hidden md:block top-[500px] left-0 absolute z-10 h-96"
				src={Barley2}
				alt="Background Barley Illustration"
			></img>
			<h1 className="text-[#FFAC5E] text-center text-[96px] font-brewski">
				Discover Saskatchewan
			</h1>
			<div className="flex flex-col-reverse md:flex-row items-center justify-center md:pl-40">
				<div className="flex flex-col md:pr-10">
					<p className="text-center text-xl max-w-md text-justify pt-4 font-[900] font-body mb-3">
						Behind almost every beer are the story of barley and the farmers and craftsmen that turn this humble grain into your favorite brew.
					</p>
					<p className="text-center text-xl max-w-md text-justify font-[900] font-sans mb-3">
						Grown in golden fields, harvested, malted and brewed, barley is the key to great-tasting beer.
					</p>
					<p className="text-center text-xl max-w-md text-justify font-[900] font-sans mb-3">
						Saskatchewan is the home of superior barley.
						And Grain Discovery is helping you discover the unique journey of your Saskatchewan-grown, malted and brewed beer.
					</p>
				</div>

				<img
					className="max-h-[600px] md:max-w-[625px] md:w-1/2 transform translate-x-10 md:translate-x-0 md:translate-y-12"
					src={saskcan}
					alt="main beer"
				></img>
			</div>
		</div>
	);
}

export default ValueProp;
