import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import Barley3 from '../../../assets/barley_illustration/barley_3.png'
import Barley4 from '../../../assets/barley_illustration/barley_4.png'
import TimelineBar from '../../../components/TimelineBar'
import Footer from '../footer/Footer'
import marketsMalt from '../../../assets/makers-malt.svg'
import maltTypes from '../../../assets/maltTypes.png'
import process from '../../../assets/process.png'
import useIntersectionObserver from '../SaskBrewing/useIntersectionObserver'

export default function SaskMalts() {
    const history = useHistory()
    const ref = useRef();

    const [isVisible, setIsVisible] = useState(false);

    useIntersectionObserver({
        target: ref,
        onIntersect: ([{ isIntersecting }], observerElement) => {
            if (isIntersecting) {
                setIsVisible(true);
                observerElement.unobserve(ref.current);
            }
        }
    });

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='bg-background h-full'>
            <div className="flex justify-center text-[25px] pt-10 h-[60px] relative">
                <img
                    className="hidden md:block top-0 right-0 absolute z-10"
                    src={Barley3}
                    alt="Background Barley Illustration"
                ></img>
                <img
                    className="hidden md:block top-0 left-10 absolute z-10"
                    src={Barley4}
                    alt="Background Barley Illustration"
                ></img>
                <span className="font-brewski text-black border-r border-black pr-3 cursor-pointer hover:text-[27px]" onClick={() => history.push("/")}>Home</span>
                <a className="font-brewski text-black pl-3 cursor-pointer hover:text-[27px]" href="#contact">Contact Us</a>
            </div>
            <h1 className="text-[#6B3B38] text-center text-[96px] font-brewski">
                Saskatchewan Malts
            </h1>
            <TimelineBar active={"malt"} />
            <div className='mt-20 flex flex-col items-center'>
                <h1 className="font-brewski text-[52px] tablet:text-[52px] text-center pb-5">
                    Malting in Sask
                </h1>
                <div className="mb-20 flex justify-center">
                    <div className="border-b-8 rounded border-[#FFAC5E] w-[250px]" />
                </div>
                <div className='w-4/5 font-body text-[26px]'>
                    <p className='font-body mb-3'>There are some well-known malting facilities in Saskatchewan, companies like Boort Malt/Prairie Malt, and smaller artesian operations like Maker’s Malt. These Malt Houses are the translators between what brewers are looking for in terms of varieties, flavour profiles, and other factors and what the certified seed growers are planting. Malt houses serve a vital function as both a producer of a critical ingredient in the brewing process and a link between brewers and growers.</p>
                    <div className='flex'>
                        <div>
                            <p className='font-body mb-3'>Malt is a versatile and critical ingredient allowing many creative ways to utilize it in the brewing process. Craft Brewers like Regina-based Malty National experiment nearly weekly with different malts meaning that requests from malt houses can be frequent and expansive in variety.</p>
                            <p className='font-body mb-3'>When looking for malt barley varieties to use in the malting process, brewers consider many variables. Certain malts have excellent flavour enhancements or profiles, certain malts have a higher extract content, higher protein contents and higher starch contents that affect yield capabilities and flavouring.</p>
                        </div>
                        <img src={marketsMalt} alt="Malt Markets" className="w-4/5 mt-10" loading='lazy' />
                    </div>
                </div>
            </div>
            <img src={maltTypes} alt="Malt Types" className="mx-auto mb-20 mt-20" loading="lazy" />
            <div ref={ref}>
                {isVisible && (
                    <img src={process} alt="Malt Process" className="mx-auto mb-20 mt-30" loading="lazy" />
                )}
            </div>
            <h3 className="text-[#6B3B38] text-center text-[56px] font-brewski">
                Saskatchewan Brewing
            </h3>
            <p className='font-body text-center w-1/2 text-[26px] mx-auto'>“One of the defining characteristics of Saskatchewan craft brewing is passion. So many started as home brewers who enjoyed the process before making a beer so good that it would be criminal not to sell it!” - Bert Barkwell, Beer Historian</p>
            <div className='flex flex-col items-center mb-20'>
                <button
                    onClick={() => history.push("/saskatchewan-brewing")}
                    className="bg-[#00374B] text-white text-[38px] font-brewski w-[300px] h-[70px] rounded-lg mx-auto mt-10 mb-20">
                    Discover More
                </button>
            </div>
            <Footer />
        </div>
    )
}