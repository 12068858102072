import * as React from 'react';
import { useState, useMemo } from 'react';
// eslint-disable-next-line import/no-webpack-loader-syntax
import Map, {
    Marker,
    Popup,
    NavigationControl,
} from '!react-map-gl';

import Pin from './Pin';

import { BREWERIES } from './breweries'

import 'mapbox-gl/dist/mapbox-gl.css';

const TOKEN = 'pk.eyJ1IjoicGJyaWFyIiwiYSI6ImNsMDZwYnhzbDBsa3AzaW81cmd4c3JsemoifQ.CsCEdYOQZNK3qjnFtgVuNQ';

export default function MapGL() {
    const [popupInfo, setPopupInfo] = useState(null);
    const [state, setState] = useState({
        viewport: {
            width: '100%',
            height: 900,
            latitude: 52.11562872425346,
            longitude: -106.33967907802956,
            zoom: 5.5
        },
    })

    const handleViewportChange = (viewport) => {
        setState(prevState => ({
            viewport: { ...prevState.viewport, ...viewport }
        }))
    }

    const pins = useMemo(
        () =>
            BREWERIES.map((brewery, index) => (
                <Marker
                    key={`marker-${index}`}
                    longitude={brewery.lng}
                    latitude={brewery.lat}
                    anchor="bottom"
                    onClick={e => {
                        // If we let the click event propagates to the map, it will immediately close the popup
                        // with `closeOnClick: true`
                        e?.originalEvent?.stopPropagation();
                        setPopupInfo(brewery);
                    }}
                >
                    <Pin />
                </Marker>
            )),
        []
    );

    return (
        <div>
            <Map
                mapStyle="mapbox://styles/mapbox/streets-v9"
                mapboxAccessToken={TOKEN}
                onViewportChange={handleViewportChange}
                {...state.viewport}
            >

                {pins}

                {popupInfo && (
                    <Popup
                        anchor="top"
                        longitude={Number(popupInfo.lng)}
                        latitude={Number(popupInfo.lat)}
                        onClose={() => setPopupInfo(null)}
                    >
                        <div className='rounded-lg p-2'>
                            {/* <img width="100%" alt="brewery logo" src={popupInfo.image} /> */}
                            <p className='font-brewski text-[26px] text-[#FFAC5E] mb-0'>{popupInfo.name}</p>
                            <p className='font-body mb-0'>{popupInfo.address}</p>
                            <a
                                target="_new"
                                href={popupInfo.link}
                                className='font-body'
                            >
                                {popupInfo?.link}
                            </a>
                        </div>
                    </Popup>
                )}
                <NavigationControl />

            </Map>
        </div>
    );
}