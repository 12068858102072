import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import Barley3 from '../../../assets/barley_illustration/barley_3.png'
import Barley4 from '../../../assets/barley_illustration/barley_4.png'
import TimelineBar from '../../../components/TimelineBar'
import history1 from '../../../assets/history-pt1.svg'
import history2 from '../../../assets/history-pt2.svg'
import history3 from '../../../assets/history-pt3.png'
import history4 from '../../../assets/history-pt4.png'
import history5 from '../../../assets/history-pt5.png'
import history6 from '../../../assets/history-pt6.png'
import history7 from '../../../assets/history-pt7.png'
import history8 from '../../../assets/history-pt8.png'
import twirlDownLeft from '../../../assets/twirl-arrow-down-left.svg'
import twirlDownLeftBrown from '../../../assets/twirl-down-left-brown.svg'
import twirlDownLeftMaroon from '../../../assets/twirl-down-left-maroon.svg'
import twirlDownRightDarkBrown from '../../../assets/twirl-down-right-dark-brown.svg'
import twirlDownRightOrange from '../../../assets/twirl-down-right-orange.svg'
import Footer from '../footer/Footer'
import useIntersectionObserver from './useIntersectionObserver'

export default function SaskBrewing() {
    const history = useHistory()
    const ref = useRef();

    const [isVisible, setIsVisible] = useState(false);

    useIntersectionObserver({
        target: ref,
        onIntersect: ([{ isIntersecting }], observerElement) => {
            if (isIntersecting) {
                setIsVisible(true);
                observerElement.unobserve(ref.current);
            }
        }
    });

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='bg-background h-full'>
            <div className="flex justify-center text-[25px] pt-10 h-[60px]lative">
                <img
                    className="hidden md:block top-0 right-0 absolute z-10"
                    src={Barley3}
                    alt="Background Barley Illustration"
                ></img>
                <img
                    className="hidden md:block top-0 left-10 absolute z-10"
                    src={Barley4}
                    alt="Background Barley Illustration"
                ></img>
                <span className="font-brewski text-black border-r border-black pr-3 cursor-pointer hover:text-[27px]" onClick={() => history.push("/")}>Home</span>
                <a className="font-brewski text-black pl-3 cursor-pointer hover:text-[27px]" href="#contact">Contact Us</a>
            </div>
            <h1 className="text-[#6B3B38] text-center text-[96px] font-brewski">
                Saskatchewan Brewing
            </h1>
            <TimelineBar active={"brewing"} />
            <div className='mt-20 flex flex-col items-center'>
                <div className='w-4/5 font-body text-[26px]'>
                    <img src={history1} alt="history1" className="w-full" />
                    <img src={history2} alt="history2" className="w-full" />
                    <div ref={ref}>
                        {isVisible && (
                            <>
                                <div className='flex justify-center'><img src={history3} alt="history3" className="w-2/3 mb-[70px]" loading="lazy" /> <img src={twirlDownLeft} alt="twirl-down-left" className="ml-2" /></div>
                                <div className='flex justify-center'><img src={twirlDownRightOrange} alt="twirl-down-right-orange" className="mr-2" /><img src={history4} alt="history4" className="w-2/3 mb-[60px]" loading="lazy" /></div>
                                <div className='flex justify-center'><img src={history5} alt="history5" className="w-2/3 mb-[50px]" loading="lazy" /> <img src={twirlDownLeftBrown} alt="twirl-down-left-brown" className="ml-2" /></div>
                                <div className='flex justify-center'><img src={twirlDownRightDarkBrown} alt="twirl-down-right-dark-brown" className="mr-2" /><img src={history6} alt="history6" className="w-2/3 mb-[70px]" loading="lazy" /> </div>
                                <div className='flex justify-center'><img src={history7} alt="history7" className="w-2/3 mb-[70px]" loading="lazy" /> <img src={twirlDownLeftMaroon} alt="twirl-down-left-maroon" className="ml-2" /></div>
                            </>
                        )}
                    </div>
                    <img src={history8} alt="history8" className="w-2/3 ml-[100px] ml-[300px]" />
                </div>
            </div>
            <h3 className="text-[#6B3B38] text-center text-[56px] font-brewski">
                Saskatchewan Breweries
            </h3>
            <p className='font-body text-center w-1/2 text-[26px] mx-auto'>"At this point, it feels like there is a craft brewery dotted across nearly every town in Saskatchewan, and I, for one, believe we’re better off for it“ - Bert Barkwell, Beer Historian</p>
            <div className='flex flex-col items-center mb-20'>
                <button
                    onClick={() => history.push("/saskatchewan-breweries")}
                    className="bg-[#00374B] text-white text-[38px] font-brewski w-[300px] h-[70px] rounded-lg mx-auto mt-10 mb-20">
                    Discover More
                </button>
            </div>
            <Footer />
        </div>
    )
}